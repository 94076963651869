/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Y llevan ya unos meses dando la murga con esta idea pero no solo Telefónica, las grandes compañías de telecomunicaciones europeas se han subido al carro, en el video de a continuación veremos las declaraciones del presidente de Telefónica, comentadas por los usuarios de youtube, sinceramente no pueden ser mas absurdas, mal acabaremos en este país si no se cambia la forma de hacer el negocio de las redes de datos."), "\n", React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=rVADWAxOZtg]"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
